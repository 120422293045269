import React from "react";
import '../App.css';

const Experience = ({user}) =>{
    return(
        <div id="experience" class="pt-10">

            <h2 className="text-2xl font-bold mb-4 my-10 text-white mx-5 ">Experience</h2>
            
            {user.experience.map((experience, index) => (
              <div class="space--4 text-white">
                 <div key={index} class="lg:flex sm:px-4 px-6 hover:rounded-lg mt-4">
                     <div class=" pt-4 pl-2 lg:pl-2 lg:w-10">
                        <a class="text-sm pt-6 px-0">{experience.startDate}-{experience.endDate ?? "present"}</a>
                     </div>
                        <div className="py-0 sm:py-4 px-2 lg:px-10 pb-4 border-b-2 border-b-amber-700">
                        <h3 className="text-lg font-bold">{experience.position}</h3>
                            <a><i>{experience.company}</i></a>
                            <p class="pt-4 text-justify hidden md:block">{experience.description}</p>
                            <p>{experience.location}</p>
                            <div class="flex mt-2 flex-wrap">
                                {experience.technologies.map((technology,index) =>(
                                    <ul class="bg-green-300 rounded-full py-1 px-3 text-xs sm:text-sm  font-semibold text-gray-700 mr-2 mb-2">{technology}</ul>
                                ))}
                            </div>
                        </div>
                    
                </div>
                
              </div>
            ))}

        </div>

    )
}

export default Experience;