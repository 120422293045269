import React from 'react';
import '../App.css';
import Experience from './Experience';
import Education from './Education';
import Projects from './Projects';
import Volunteer from './Volunteer';

const Timeline = ({user}) => {
    
    return (
        <section className="w-full md:w-full lg:w-4/6 text-white px-8 py-8  lg:ml-auto">

            <div className="font-mono text-blue-400 sm:py-2 lg:py-4 lg:px-20 text-justify ">
                <a id="about" class="pt-20">{user.about}</a>
            </div>
            
            <Experience user={user} />
            <Education user={user}/>
            <Projects user={user}/>
            <Volunteer user={user}/>
        </section>
    );
}

export default Timeline;
