import React from "react";
import '../App.css';


const Projects = ({ user }) => {
    return (
        <div id="projects" className="pt-10 text-white mx-5">
            <h2 className="text-2xl font-bold mb-4 my-10">Projects</h2>
            {user.projects.map((project, index) => (
                <div key={index} className="space-y-4 mb-4 hover:rounded-xl">
                    <div className="sm:flex hover:bg-gray-700  border-4 sm:border sm:bg-transparent lg:border-none border-blue-300  hover:border-blue-300 rounded-xl ">
                        <div className=" sm:pl-4 flex items-center justify-center sm:w-2/6 ">
                            <img
                                src={project.thumbnail}
                                className="w-full h-48 sm:h-62 sm:m-4 object-cover rounded-t-xl sm:rounded-xl" 
                                alt={project.projectName}
                            />
                        </div>
                        <div className="py-4 px-6 hover:rounded-xl hover:bg-gray-700 flex-1 ">
                            <h3 className="text-lg font-bold">{project.projectName}</h3>
                            <p className="mt-2">{project.description}</p>
                            <div className="flex flex-wrap mt-4">
                                {project.technologies.map((technology, index) => (
                                    <div key={index} className="bg-gray-200 rounded-full py-1 px-3 text-sm font-semibold text-gray-700 mr-2 mb-2">
                                        {technology}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>

    );
};


export default Projects