import React from 'react';
import './App.css';
// import axios from'axios';
import { useState, useEffect, useLayoutEffect } from 'react';

import Home from './components/Home';
import Timeline from './components/Timeline';
import Technologies from './components/Technologies';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Education from './components/Education';
import Experience from './components/Experience';
import Projects from './components/Projects';
import Volunteer from './components/Volunteer';


function App() {

  const [scrollY, setScrollY] = useState(0);
  const [userInfo, setUserInfo] = useState();

  useLayoutEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [user, setUser] = useState({
    "name": "Poorna Chandan Reddy Vavilala",
    "currentRole": "Software Engineer",
    "githubLink": "https://github.com/chandan-vavilala",
    "linkedinLink": "https://linkedin.com/in/chandan-vavilala",
    "xLink": "https://x.com/Chandan_channu",
    "email": "chandanreddyvavilala@gmail.com",
    "about": "Software Engineer with over 2+ years of experience in designing, developing, and deploying scalable web applications using Java, ASP.NET, and modern frameworks. Proficient in both front-end and back-end technologies, including building RESTful APIs with Spring Boot and ASP.NET Core, and creating responsive UIs with HTML, CSS, JavaScript, Angular, and React. Experienced in database management with MySQL, PostgreSQL, and Oracle, and skilled in version control using GIT. Familiar with Agile methodologies and tools like Jira and Confluence, and experienced in writing tests with JUnit, Mockito, and NUnit. Proficient in cloud deployment on AWS and Azure, and knowledgeable in CI/CD practices with Jenkins and Docker. Strong problem-solver and communicator, dedicated to continuous learning and collaboration with cross-functional teams.",
    "experience": [
      {
        "startDate": "Jul'24",
        "endDate": "Present",
        "position": "Software Developer",
        "company": "Sunmerge Technologies Inc",
        "description": "Working on Customer Complaint Tracking Software with a focus on backend support and user feedback integration. Collaborate on Java Spring Boot modules and maintaining database consistency while enhancing debugging and testing skills.",
        "location": "Piscataway, NJ, United States",
        "technologies":["HTML","JavaScript","ASP.NET","Oracle"]
      },
      {
        "startDate": "May'23",
        "endDate": "May'24",
        "position": "Graduate Assistant - Web Applications Development & Mobile Computing iOS",
        "company": "Northwest Missouri State University",
        "description": "As a graduate assistant for the Web Applications Development course, I played a key role in guiding students through modern web development, refining curriculum content, and leading hands-on lab sessions to teach programming languages, frameworks, and UI/UX principles. I provided feedback on assignments, conducted code reviews, and created a supportive learning environment to help students build practical, industry-relevant skills. Simultaneously, I contributed to mobile computing iOS projects, specializing in designing and developing applications using Swift. By collaborating closely with project teams and optimizing user interfaces for intuitive interaction, I combined my expertise in web and mobile development to deliver robust, user-friendly solutions while fostering the growth of aspiring developers.",
        "location": "Maryville, MO, United States",
        "technologies":["HTML","CSS","Markdown","Render","Java","PUG","JavaScript","Github","Swift","Lottie","Firebase"]
      },
      {
        "startDate": "Feb'22",
        "endDate": "Dec'22",
        "position": "Associate Software Developer",
        "company": "GQBAY Software Pvt Ltd",
        "description": "As an associate software developer, I contributed to the design, development, and maintenance of software applications within a collaborative team environment. Leveraging my foundational knowledge and skills acquired through education and practical experience, I actively participated in coding, testing, and debugging to ensure robust functionality and optimal performance of applications. I collaborated closely with senior developers to implement technical solutions, adhere to coding standards, and integrate new technologies as part of continuous learning and improvement. ",
        "location": "Gachibowli, TG, India",
        "technologies":["HTML","CSS","Java","JavaScript",".NET","Azure","SQLServer"]
      },
      {
        "startDate": "Oct'21",
        "endDate": "Nov'21",
        "position": "Web Developer Intern",
        "company": "Exposys DataLabs",
        "description": "Created a website as a part of internship programme",
        "location": "Bangalore, KA, India",
        "technologies":["HTML","CSS","Php","Bootstrap"]
      }
    ],
    "education": [
      {
      
        "courseName": "Masters in Applied Computer Science",
        "collegeName": "Northwest Missouri State University",
        "coverphoto": "./images/nwmsu.png",
        "relevantcourses": "Developing Web Applications and Services, Object Oriented Programming, Advanced Database Systems, Mobile Computing: iOS, Project Management in Business and Technology, Application Design: Patterns and Frameworks, Big Data and Data Visualization." ,
        "location": "Maryville, MO, United States", 
      },
      {
      
        "courseName": "Bachelors in Computer Science and Information Systems",
        "collegeName": "MVJ College of Engineering",
        "coverphoto": "./images/mvj.png",
        "location": "Whitefield Bangalore, India"
      }
    ],
    "projects": [
      {
        "thumbnail": "./images/mern.jpg",
        "projectName": "Perfomatrics for School Evaluation",
        "description": "Developed a web application for school evaluation using React, Node.js, MongoDB, and other technologies.",
        "technologies": ["React.js","Express.js","Node.js","MongoDB","Firebase","Python","Tailwind CSS","Font Awesome Icons","Chart.js"]
      },
      {
        "thumbnail": "./images/swift.jpg",
        "projectName": "NWMSU Employment Application - iOS",
        "description": "Created an iOS application for managing employment applications using Swift and Firebase.",
        "technologies": ["Swift","Firebase","Lottie"]
      },
      {
        "thumbnail": "./images/tableau.jpg",
        "projectName": "Data Visualization - Tableau",
        "description": "Designed interactive data visualizations using Tableau and Tableau Prep.",
        "technologies": ["Tableau","Tableau Prep"]
      },
      {
        "thumbnail":"./images/mysql.jpg",
        "projectName": "MySQL Database Management",
        "description": "Managed and optimized MySQL databases for various projects.",
        "technologies": ["MySQL","PhpMyAdmin","HTML","CSS"]
      },{
        "thumbnail":"./images/htmlcss.jpg",
        "projectName": "ARB - Static E-Commerce Website",
        "description": "Developed a static e-commerce website that helps businesses reach their customers online.",
        "technologies": ["HTML","CSS","BootStrap"]
      },{
        "thumbnail":"./images/haretortoise.jpg",
        "projectName": "Hare and Tortoise Game",
        "description": "The Hare and Tortoise Game is a graphical representation of the famous fable., The Tortoise and the Hare, which is often used to teach the moral that slow and steady wins the race.",
        "technologies": ["C","OpenGL","GLUT"]
      }
    ],
    "workedtechnologies": ["React","Vue.js","Node.js","HTML5","CSS3","Python","Java","PHP","Markdown","GitHub","GitLab","FontAwesome","Figma",""],
    "volunteer":[
      {"NGO":"Happy Happo Hub",
       "role":"Community Lead",
       "startDate":"Jan'17",
       "endDate":"Dec'17",
       "images":["./images/foodDonation.jpg","./images/lalanaOrphanage.jpg","./images/Plantation.jpg"],
       "description":"Contributed to the core team of an NGO. Organized and facilitated various social events to foster community engagement. Conducted 12 events around the year."
    }
  ]
  });

  const [currentPage, setCurrentPage] = useState(localStorage.getItem('currentPage') || 'Home');

  const [mobileSize, setMobileSize] = useState(window.innerWidth < 640); // Initialize based on current window size

  useEffect(() => {
    // Save the current page to localStorage whenever it changes
    localStorage.setItem('currentPage', currentPage);
  }, [currentPage]);

  useEffect(() => {
      const handleResize = () => {
          setMobileSize(window.innerWidth < 640); // Update mobileSize based on window width
      };

      window.addEventListener('resize', handleResize);
      // Cleanup listener on unmount
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);


    const renderComponent = () => {
        switch (currentPage) {
            case 'Home': 
                return <Home user={user} />;
            case 'Experience':
                return <Experience  user={user}/>;
            case 'Education':
                return <Education user={user} class="gradient-bg-1" />;
            case 'Projects':
                return <Projects  user={user}/>;
            case 'Skills':
                return <Technologies  user={user}/>;
            case 'Volunteer':
                return <Volunteer  user={user} class="gradient-bg-1" />;
            case 'Connect':
                return <Footer  user={user} class="gradient-bg-1" />;
            default:
                return <div>Page not Available</div>; // Default or Home component
        }
    };

  return mobileSize ? (

      <div class="gradient-bg-1">
          <div className=" mb-10 pb-16 ">  {renderComponent()} </div>
          <Navbar setCurrentPage={setCurrentPage}/>
      </div> 

  ):(
    <div>
      <div className="lg:flex flex-row mx-auto gradient-bg">
          <Home scrollY={scrollY} user={user} />
          <Timeline user={user}/>
      </div>
          <Technologies user={user}/>
          <Footer user={user}/>
    </div>
  )
}


export default App;


