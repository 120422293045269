import React from "react";
import '../App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub,faXTwitter,faLinkedin, faReact, faNodeJs, faFontAwesome, faSuperpowers, faSquareGitlab, faHtml5, faCss3, faPython, faJava, faJs, faFigma, faVuejs, faNode, faMarkdown, faPhp, faHashnode, faMailchimp, faBlackTie } from '@fortawesome/free-brands-svg-icons';


const Technologies = ({user}) =>{

    return(
        <div class="pt-6 text-center items-center bg-blend-darken" id="skills">
            <a class="text-blue-300 mx-2 text-xl font-mono p-2">Tools and Technologies I have worked with</a>
            <div class="flex justify-center mt-6 mx-6 lg:mx-56">
            <ul class="flex flex-wrap text-white">
                <li class="py-10 px-6 hover:pt-0" title="React"><FontAwesomeIcon icon={faReact} style={{ fontSize: '50px' }}/></li>
                <li class="py-10 px-6 hover:pt-0" title="Vue.js"><FontAwesomeIcon icon={faVuejs} style={{ fontSize: '50px' }}/></li>
                <li class="py-10 px-6 hover:pt-0" title="Node.js"><FontAwesomeIcon icon={faNode} style={{ fontSize: '50px' }}/></li>
                <li class="py-10 px-6 hover:pt-0" title="JavaScript"><FontAwesomeIcon icon={faJs} style={{ fontSize: '50px' }}/></li>

                <li class="py-10 px-6 hover:pt-0" title="HTML5"><FontAwesomeIcon icon={faHtml5} style={{ fontSize: '50px' }}/></li>
                <li class="py-10 px-6 hover:pt-0" title="CSS3"><FontAwesomeIcon icon={faCss3} style={{ fontSize: '50px' }}/></li>
                <li class="py-10 px-6 hover:pt-0" title="Python"><FontAwesomeIcon icon={faPython} style={{ fontSize: '50px' }}/></li>
                <li class="py-10 px-6 hover:pt-0" title="Java"><FontAwesomeIcon icon={faJava} style={{ fontSize: '50px' }}/></li>

                <li className="py-10 px-6 hover:pt-0" title="C#">
                    <img src="./icons/csharp.png" alt="C#" style={{ width: '50px', height: '50px', filter: 'invert(1)'  }}/>
                </li>
                <li className="py-10 px-6 hover:pt-0" title="ASP.NET">
                    <img src="./icons/aspnet.png" alt="ASP.NET" style={{ width: '50px', height: '50px', filter: 'invert(1)'  }}/>
                </li>


                <li class="py-10 px-6 hover:pt-0" title="PHP"><FontAwesomeIcon icon={faPhp} style={{ fontSize: '50px' }}/></li> 
                <li class="py-10 px-6 hover:pt-0" title="Markdown"><FontAwesomeIcon icon={faMarkdown} style={{ fontSize: '50px' }}/></li> 

                <li class="py-10 px-6 hover:pt-0" title="GitHub"><FontAwesomeIcon icon={faGithub} style={{ fontSize: '50px' }}/></li>
                <li class="py-10 px-6 hover:pt-0" title="GitLab"><FontAwesomeIcon icon={faSquareGitlab} style={{ fontSize: '50px' }}/></li>
                
                <li class="py-10 px-6 hover:pt-0" title="Font Awesome"><FontAwesomeIcon icon={faFontAwesome} style={{ fontSize: '50px' }}/></li>
                <li class="py-10 px-6 hover:pt-0" title="Figma"><FontAwesomeIcon icon={faFigma} style={{ fontSize: '50px' }}/></li> 

                <li className="py-10 px-6 hover:pt-0" title="Azure">
                    <img src="./icons/azure.png" alt="Azure" style={{ width: '50px', height: '50px', filter: 'invert(1)' }}/>
                </li>
                <li className="py-10 px-6 hover:pt-0" title="ChartJS">
                    <img src="./icons/chartjs.png" alt="Chart JS" style={{ width: '50px', height: '50px', filter: 'invert(1)'  }}/>
                </li>
                <li className="py-10 px-6 hover:pt-0" title="Express">
                    <img src="./icons/express.png" alt="Express" style={{ width: '50px', height: '50px', filter:'invert(1)' }}/>
                </li>
                <li className="py-10 px-6 hover:pt-0" title="Firebase">
                    <img src="./icons/firebase.png" alt="Firebase" style={{ width: '50px', height: '50px',   filter:'invert(1)'  }}/>
                </li>
                <li className="py-10 px-6 hover:pt-0" title="Lottie">
                    <img src="./icons/Lottie.png" alt="Lottie" style={{ width: '50px', height: '50px', filter: 'invert(1)'  }}/>
                </li>
                <li className="py-10 px-6 hover:pt-0" title="MongoDB">
                    <img src="./icons/mongodb.png" alt="MongoDB" style={{ width: '50px', height: '50px', filter: 'invert(0)' }}/>
                </li>
                <li className="py-10 px-6 hover:pt-0" title="Oracle">
                    <img src="./icons/oracle.png" alt="Oracle" style={{ width: '50px', height: '50px', filter: 'invert(1)'  }}/>
                </li>
                <li className="py-10 px-6 hover:pt-0" title="Swift">
                    <img src="./icons/swift.png" alt="Swift" style={{ width: '50px', height: '50px'}}/>
                </li>
                <li className="py-10 px-6 hover:pt-0" title="Tableau">
                    <img src="./icons/tableau.png" alt="Tableau" style={{ width: '50px', height: '50px', filter: 'invert(1)'  }}/>
                </li>
                
            </ul>

            </div>
        </div>

    )
}


export default Technologies;