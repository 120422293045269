import React from "react";
import '../App.css';

const Education =({user})=>{
    return (
        <div id="education" class="pt-10 mx-5 text-white">
        <h2 className="text-2xl font-bold mb-4 my-10 ">Education</h2>
       {user.education.map((education, index) => (

            <div key={index} className="space-y-4 sm:px-8 px-6">
            <div class="lg:flex py-3">
 
            <div
                className="bg-gray-700 py-4 px-5 rounded-lg"
                style={{ backgroundImage: `url(${education.coverphoto})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundBlendMode:'darken'  }}
                >

                    <h3 className="text-lg font-bold">{education.courseName}</h3>
                    <a class="text-lg">{education.collegeName}</a>
                    <p>{education.location}</p>
                  
                </div>
            </div>
            </div>

        ))}
        </div>
    )
}

export default Education;