import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../App.css';

import { useState, useEffect, useLayoutEffect } from 'react';

import Experience from './Experience';
import Education from './Education';
import Projects from './Projects';
import Home from './Home';
import Timeline from './Timeline';
import Technologies from './Technologies';
import Footer from './Footer';

import { faHome, faClipboardList, faGraduationCap, faBriefcase, faStar, faUserFriends, faHandshake,faHeart } from '@fortawesome/free-solid-svg-icons';



const Navbar = ({ setCurrentPage }) => {

    const [mobileSize, setMobileSize] = useState(window.innerWidth < 640); // Initialize based on current window size

    useEffect(() => {
        const handleResize = () => {
            setMobileSize(window.innerWidth < 640); // Update mobileSize based on window width
        };
  
        window.addEventListener('resize', handleResize);
  
        // Cleanup listener on unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
  
  

    return mobileSize ? (

            <div class="navbar-mobile rounded-t-3xl {mobileSize}m-0:m-8 bg-slate-950 ">
               <ul class="flex flex-wrap py-4 px-2 text-orange-400 justify-center font-sans">
                <li className="px-1" onClick={() => setCurrentPage('Home')} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <a href="#about" title="Home" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textDecoration: 'none' }}>
                        <FontAwesomeIcon icon={faHome} style={{ fontSize: '20px' }} />
                        <span>Home</span>
                    </a>
                </li>
                           
                <li className="px-1" onClick={() => setCurrentPage('Education')} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <a href="#education" title="Education" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textDecoration: 'none' }}>
                    <FontAwesomeIcon icon={faGraduationCap} style={{ fontSize: '20px' }} />
                    <span>Education</span>
                    </a>
                </li>
                
                <li className="px-1" onClick={() => setCurrentPage('Experience')} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <a href="#experience" title="Experience" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textDecoration: 'none' }}>
                    <FontAwesomeIcon icon={faClipboardList} style={{ fontSize: '20px' }} />
                    <span>Experience</span>
                    </a>
                </li>
                
                <li className="px-1" onClick={() => setCurrentPage('Projects')} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <a href="#projects" title="Projects" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textDecoration: 'none' }}>
                    <FontAwesomeIcon icon={faBriefcase} style={{ fontSize: '20px' }} />
                    <span>Projects</span>
                    </a>
                </li>
                
                <li className="px-1" onClick={() => setCurrentPage('Skills')} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <a href="#skills" title="Skills" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textDecoration: 'none' }}>
                    <FontAwesomeIcon icon={faStar} style={{ fontSize: '20px' }} />
                    <span>Skills</span>
                    </a>
                </li>
                <li className="px-1" onClick={() => setCurrentPage('Volunteer')} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <a href="#volunteer" title="CoVolunteernnect" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textDecoration: 'none' }}>
                    <FontAwesomeIcon icon={faHeart} style={{ fontSize: '20px' }} />
                    <span>Volunteer</span>
                    </a>
                </li>
                <li className="px-1" onClick={() => setCurrentPage('Connect')} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <a href="#connect" title="Connect" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textDecoration: 'none' }}>
                    <FontAwesomeIcon icon={faUserFriends} style={{ fontSize: '20px' }} />
                    <span>Connect</span>
                    </a>
                </li>
               </ul>
 
            </div>
           
            ) : (
            
            <div class=" rounded-3xl m-8 bg-slate-950">
                <ul class="flex flex-wrap py-6 text-orange-500 justify-center font-sans">
                    <li class="px-2"><a href="#about"> {mobileSize?<FontAwesomeIcon icon={faHome}/>:null}{mobileSize ? "":"About"}</a></li>
                    <li class="px-2"><a href="#experience">{mobileSize?<FontAwesomeIcon icon={faClipboardList}/>:null} {mobileSize ? "" : "Experience"}</a></li>
                    <li class="px-2"><a href="#education">{mobileSize?<FontAwesomeIcon icon={faGraduationCap}/>:null} {mobileSize ? "":"Education"}</a></li>
                    <li class="px-2"><a href="#projects">{mobileSize?<FontAwesomeIcon icon={faBriefcase}/>:null} {mobileSize ? "":"Projects"}</a></li>
                    <li class="px-2"><a href="#skills">{mobileSize?<FontAwesomeIcon icon={faStar}/>:null} {mobileSize ? "":"Skills"}</a></li>
                    <li class="px-2"><a href="#volunteer">{mobileSize?<FontAwesomeIcon icon={faUserFriends}/>:null} {mobileSize ? "":"Volunteer"}</a></li>
                    <li class="px-2"><a href="#connect">{mobileSize?<FontAwesomeIcon icon={faUserFriends}/>:null} {mobileSize ? "":"Connect"}</a></li>
                </ul>
            </div>
    )
};


export default Navbar;



{/* <li class="px-2"><a href="#about"> {mobileSize?<FontAwesomeIcon icon={faHome}/>:null}{mobileSize ? "":"About"}</a></li>
<li class="px-2"><a href="#experience">{mobileSize?<FontAwesomeIcon icon={faClipboardList}/>:null} {mobileSize ? "" : "Experience"}</a></li>
<li class="px-2"><a href="#education">{mobileSize?<FontAwesomeIcon icon={faGraduationCap}/>:null} {mobileSize ? "":"Education"}</a></li>
<li class="px-2"><a href="#projects">{mobileSize?<FontAwesomeIcon icon={faBriefcase}/>:null} {mobileSize ? "":"Projects"}</a></li>
<li class="px-2"><a href="#skills">{mobileSize?<FontAwesomeIcon icon={faStar}/>:null} {mobileSize ? "":"Skills"}</a></li>
<li class="px-2"><a href="#connect">{mobileSize?<FontAwesomeIcon icon={faUserFriends}/>:null} {mobileSize ? "":"Connect"}</a></li> */}
