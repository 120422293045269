import React ,{useState} from "react";
import '../App.css';

const Volunteer = ({user}) =>{
    return(
        <div id="volunteer" class="pt-10">

            <h2 className="text-2xl font-bold mb-4 my-10 text-white mx-5 ">Volunteer Experience</h2>
            
            {user.volunteer.map((volunteer, index) => (
              <div class="space--4 text-white">
                 <div key={index} class="lg:flex sm:px-4 px-6 hover:rounded-lg mt-4">
                        <div class=" pt-4 pl-2 lg:pl-2 lg:w-10">
                            <a class="text-sm pt-6 px-0">{volunteer.startDate}-{volunteer.endDate ?? "present"}</a>
                        </div>

                        <div className="py-0 sm:py-4 px-2 lg:px-10 pb-4 border-b-2 border-b-amber-700">
                        <h3 className="text-lg font-bold text-green-300">{volunteer.role}</h3>
                            <a><i>{volunteer.NGO}</i></a>
                            <p class="pt-4 text-justify  md:block text-blue-500">{volunteer.description}</p>
                        </div>
                       
                </div>
                <div className="py-4 px-2 sm:hidden">
                            <VolunteerSlideshow images={volunteer.images} />
                </div>
                    
              </div>
            ))}

        </div>

    )
}

const VolunteerSlideshow = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const prevImage = () => {
        setCurrentIndex(
            (prevIndex) => (prevIndex - 1 + images.length) % images.length
        );
    };

    return (
        <div className="relative">
            {images.length > 0 ? (
                <div>
                    {/* Image Display */}
                    <img
                        src={images[currentIndex]}
                        alt={`Volunteer Experience ${currentIndex}`}
                        className="w-full h-auto rounded-lg shadow-md "
                    />
                    <div className="absolute top-1/2 left-0 right-0 flex justify-between transform -translate-y-1/2 px-4">
                        <button
                            onClick={prevImage}
                            className="bg-black bg-opacity-50 text-white p-2 rounded-full"
                        >
                            &lt;
                        </button>
                        <button
                            onClick={nextImage}
                            className="bg-black bg-opacity-50 text-white p-2 rounded-full"
                        >
                            &gt;
                        </button>
                    </div>
                </div>
            ) : (
                <p className="text-white text-center">No images available.</p>
            )}
        </div>
    );
};

export default Volunteer;