import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect, useLayoutEffect } from 'react';

import { faGithub, faLinkedin, faXTwitter} from '@fortawesome/free-brands-svg-icons';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import DP from './images/photoVAVILALA.JPG';
import '../App.css';
import Navbar from './Navbar';


import { faHome, faClipboardList, faGraduationCap, faBriefcase, faStar, faUserFriends } from '@fortawesome/free-solid-svg-icons';



const Home = ({scrollY,user,mobileSize}) => {

  

    return(
        <aside className={`w-full  md:w-full lg:sticky lg:w-2/6 lg:h-screen sm:px-8 pt-10 overflow-y-auto ${scrollY>0 ? 'lg:sticky lg:top-0' : ''}`}>
          <div className="">
            <div class="profileimagecontainer">
                <img src={DP} alt="Chandan Vavilala" className="logo w-48 h-48 rounded-full bg-gray-700" />
            </div>

            <div className="flex items-center justify-center space-x-4 px-2 py-2">
                <div class="text-center ">
                    <h1 className="text-cyan-400 font-extrabold  text-xl sm:text-4xl elevate rounded-lg pt-10 sm:pt-10 pb-5">{user.name}</h1>
                    <p className="text-cyan-600 text-xl font-bold"><i>{user.currentRole}</i></p>
                </div>
            </div>
          
            </div> 
            <nav className="mt-10 ">
        
         
                <ul className="text-white flex flex-wrap justify-center">
                    <li><a href={user.githubLink} className="socialaccounts bg-black"><FontAwesomeIcon icon={faGithub}/></a></li>
                    <li><a href={user.linkedinLink} className="socialaccounts bg-blue-600"><FontAwesomeIcon icon={faLinkedin}/></a></li>
                    <li><a href={user.xLink}className="socialaccounts bg-black"><FontAwesomeIcon icon={faXTwitter}/></a></li>
                </ul>
                
                <a href="/resume.pdf" target="_blank" className="text-blue-500 font-extrabold justify-self-center block text-center mt-10 px-0">
                    View Full Resume <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                </a>

            
                {mobileSize ? null  : <Navbar/>}

                 <div className="text-blue-500 m-8 text-justify font-mono sm:hidden">
                    {user.about}
                 </div>
                  
                
            </nav>
            
        </aside> 
);
};

export default Home;

